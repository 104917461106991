<template>
  <div class="cont">
    <router-link to="/company-profile/members" class="back">
      <Icon type="ios-arrow-back" />
      <span>Go back to Members</span>
    </router-link>

    <div class="box" style="width:100%">
      <div class="mb-10">
        Please input email addresses to send an invite link to:
      </div>
      <div class="mb-20 emails">
        <Input
          class="mr-20"
          v-model="email"
          placeholder="input email address"
          style="width: 80%"
        />
        <Button type="primary" @click="addEmail" style="width: 20%">Add</Button>
      </div>

      <div v-if="emails.length > 0" class="mb-20 email-list">
        <h3>Inviting mail List</h3>
        <div :key="email" v-for="email in emails" style="width: 100%">
          <span>{{ email }}</span>
        </div>
      </div>

      <Button
        v-if="emails.length > 0"
        :disabled="emails.length === 0"
        :loading="createLoading"
        type="primary"
        @click="createSubmit"
        >Send Invite</Button
      >
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { uniq, trim } from "lodash";
const { _inviteMember } = api;

export default {
  data() {
    return {
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      createLoading: false,

      email: "",

      emails: []
    };
  },

  methods: {
    addEmail() {
      const email = trim(this.email);
      if (email) {
        // eslint-disable-next-line no-useless-escape
        const ePattern = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;

        if (!ePattern.test(email)) {
          this.$Message.error("Illegal email address");
        } else {
          this.emails = uniq([...this.emails, email]);
        }
      }
    },

    // handleCreate(value) {
    //   this.create.emails.push(value);
    //   this.emailList.push(value);
    // },

    createSubmit() {
      if (this.emails.length > 0) {
        this.createLoading = true;
        _inviteMember({ emails: this.emails })
          .then(() => {
            this.$Message.success("success");
          })
          .catch(({ message }) => {
            this.$Message.error(message);
          })
          .finally(() => {
            this.createLoading = false;
          });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.cont {
  background: #fff;
  .box {
    width: 400px;
    padding: 20px;
    .emails {
      display: flex;
    }
    .email-list {
      border: 1px solid #ccc;
      padding: 20px;
    }
  }
}
</style>
